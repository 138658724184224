import React from "react";

function VisaSVG(props) {
  return (
    <svg
      viewBox="0 0 126 79"
      xmlns="https://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={1.41421}
      {...props}
    >
      <g fillRule="nonzero">
        <path
          d="M125.68 74.343a4.181 4.181 0 01-4.182 4.18H4.43a4.18 4.18 0 01-4.18-4.18V4.313A4.18 4.18 0 014.43.131h117.068a4.182 4.182 0 014.182 4.182v70.03z"
          fill="#3957a7"
        />
        <path
          d="M57.971 51.102h-6.127l3.83-23.534h6.127l-3.83 23.534zM46.689 27.568l-5.841 16.187-.692-3.486v.001l-2.06-10.583s-.25-2.119-2.907-2.119h-9.658l-.111.399s2.953.613 6.408 2.689l5.322 20.446h6.385l9.75-23.534h-6.596zM88.143 42.778l3.218-8.809 1.813 8.809h-5.031zm6.742 8.324h5.625l-4.905-23.534H90.68c-2.276 0-2.83 1.753-2.83 1.753l-9.137 21.781H85.1l1.275-3.495h7.791l.719 3.495zM79.191 33.227l.873-5.054s-2.697-1.026-5.509-1.026c-3.041 0-10.262 1.329-10.262 7.791 0 6.081 8.477 6.156 8.477 9.348 0 3.193-7.604 2.623-10.112.609l-.912 5.282s2.736 1.331 6.916 1.331c4.182 0 10.49-2.166 10.49-8.057 0-6.119-8.55-6.69-8.55-9.35 0-2.661 5.968-2.319 8.589-.874z"
          fill="#fff"
        />
        <path
          d="M40.156 40.27l-2.06-10.583s-.25-2.119-2.907-2.119h-9.658l-.111.399s4.641.961 9.092 4.564c4.256 3.446 5.644 7.739 5.644 7.739z"
          fill="#f9a533"
        />
      </g>
    </svg>
  );
}

export default VisaSVG;

import React from "react";
import styled from "styled-components";

import VisaSVG from "./VisaSVG";
import MastercardSVG from "./MastercardSVG";
import DiscoverSVG from "./DiscoverSVG";
import AmexSVG from "./AmexSVG";

const StyledLogos = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  img {
    margin: 0 3px 0 0;
  }
`;

const CreditCardIcons = () => (
  <StyledLogos>
    <VisaSVG width={40} />
    <MastercardSVG width={40} />
    <DiscoverSVG width={40} />
    <AmexSVG width={40} />
  </StyledLogos>
);

export default CreditCardIcons;

import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 126 79"
      xmlns="https://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeMiterlimit={1.41421}
      {...props}
    >
      <path
        d="M125.984 74.343a4.18 4.18 0 01-4.181 4.18H4.734a4.179 4.179 0 01-4.179-4.18V4.313A4.18 4.18 0 014.734.131h117.069a4.182 4.182 0 014.181 4.182v70.03z"
        fill="#13457c"
        fillRule="nonzero"
      />
      <path
        d="M78.32 61.83l1.162-.03 1.147-.083 1.123-.144 1.117-.198 1.086-.25 1.07-.304 1.047-.357 1.024-.402 1.002-.455.972-.494.942-.547.918-.584.881-.629.849-.669.82-.706.782-.751.752-.782.705-.821.67-.849.629-.881.584-.918.546-.943.493-.971.455-1.001.402-1.026.357-1.047.305-1.069.25-1.086.197-1.116.145-1.124.084-1.146.029-1.161-.029-1.154-.084-1.139-.145-1.123-.197-1.101-.25-1.085-.305-1.063-.357-1.047-.402-1.018-.455-.993-.493-.973-.546-.933-.584-.918-.629-.881-.67-.843-.705-.818-.752-.783-.782-.744-.82-.706-.849-.668-.881-.63-.918-.585-.942-.538-.972-.493-1.002-.457-1.024-.401-1.047-.357-1.07-.304-1.086-.25-1.117-.197-1.123-.144-1.147-.085-1.162-.03-1.152.03-1.147.085-1.123.144-1.109.197-1.086.25-1.07.304-1.039.357-1.026.401-.994.457-.963.493-.941.538-.912.585-.879.63-.852.668-.812.706-.781.744-.745.783-.705.818-.668.843-.623.881-.586.918-.537.933-.502.973-.447.993-.402 1.018-.35 1.047-.303 1.063-.252 1.085-.197 1.101-.142 1.123-.084 1.139-.032 1.154.032 1.161.084 1.146.142 1.124.197 1.116.252 1.086.303 1.069.35 1.047.402 1.026.447 1.001.502.971.537.943.586.918.623.881.668.849.705.821.745.782.781.751.812.706.852.669.879.629.912.584.941.547.963.494.994.455 1.026.402 1.039.357 1.07.304 1.086.25 1.109.198 1.123.144 1.147.083 1.152.03z"
        fill="#fbb231"
      />
      <path
        d="M48.217 61.83l1.152-.03 1.147-.083 1.125-.144 1.107-.198 1.086-.25 1.068-.304 1.041-.357 1.026-.402.994-.455.965-.494.939-.547.912-.584.881-.629.85-.669.812-.706.782-.751.744-.782.707-.821.666-.849.623-.881.584-.918.539-.943.502-.971.447-1.001.402-1.026.35-1.047.303-1.069.252-1.086.195-1.116.146-1.124.082-1.146.03-1.161-.03-1.154-.082-1.139-.146-1.123-.195-1.101-.252-1.085-.303-1.063-.35-1.047-.402-1.018-.447-.993-.502-.973-.539-.933-.584-.918-.623-.881-.666-.843-.707-.818-.744-.783-.782-.744-.812-.706-.85-.668-.881-.63-.912-.585-.939-.538-.965-.493-.994-.457-1.026-.401-1.041-.357-1.068-.304-1.086-.25-1.107-.197-1.125-.144-1.147-.085-1.152-.03-1.162.03-1.147.085-1.123.144-1.117.197-1.084.25-1.07.304-1.049.357-1.024.401-1.002.457-.97.493-.942.538-.92.585-.88.63-.85.668-.82.706-.782.744-.752.783-.705.818-.668.843-.63.881-.584.918-.547.933-.493.973-.457.993-.4 1.018-.357 1.047-.305 1.063-.25 1.085-.197 1.101-.145 1.123-.084 1.139-.029 1.154.029 1.161.084 1.146.145 1.124.197 1.116.25 1.086.305 1.069.357 1.047.4 1.026.457 1.001.493.971.547.943.584.918.63.881.668.849.705.821.752.782.782.751.82.706.85.669.88.629.92.584.942.547.97.494 1.002.455 1.024.402 1.049.357 1.07.304 1.084.25 1.117.198 1.123.144 1.147.083 1.162.03z"
        fill="#ec1c2e"
      />
      <path
        d="M60.635 25.456h11.957v-1.223H61.666l-1.031 1.223zM58.844 28.173h13.748V26.95H59.564l-.72 1.223zM57.506 30.883h15.086V29.66H58.039l-.533 1.223zM56.588 33.6h16.004v-1.221H56.969l-.381 1.221zM57.121 46.733h15.508V45.51H56.74l.381 1.223zM58.266 49.45h14.363v-1.222H57.697l.569 1.222zM59.875 52.16h12.754v-1.222H59.107l.768 1.222zM62.092 54.877h10.537v-1.23H61.021l1.071 1.23zM56.051 36.31h16.541v-1.222H56.285l-.234 1.222zM66.131 44.023h6.307V42.8h-6.081l-.226 1.223zM66.631 41.312h5.807v-1.229h-5.579l-.228 1.229zM55.633 40.083h1.638v1.229h-1.562l-.076-1.229z"
        fill="#fbb231"
      />
      <path
        d="M52.572 38.602l-.105-.008-.108-.014-.097-.015-.098-.015-.092-.015-.097-.016-.1-.014-.107-.017-.106-.014-.121-.008-.137-.008-.142-.008h-.16l-.176.008-.197.016-.219.014-.153.024-.144.023-.129.045-.115.045-.106.053-.097.061-.077.077-.068.068-.047.083-.039.084-.014.09v.092l.024.099.029.098.061.099.076.099.152.143.184.114.211.099.228.083.243.077.251.083.25.092.243.098.228.129.211.159.192.191.158.235.121.288.076.341.031.411-.031.478-.053.357-.092.311-.121.288-.152.25-.174.221-.197.189-.213.167-.234.145-.243.114-.251.098-.258.076-.266.061-.258.046-.252.022-.25.016-.234.006h-.326l-.305-.006h-.266l-.25-.007-.228-.009-.205-.015-.182-.015-.174-.023-.16-.015-.144-.03-.143-.024-.131-.037-.129-.031-.121-.046-.121-.044-.129-.046.342-1.678.068.016.084.023.106.029.129.032.137.029.15.031.168.037.182.031.189.03.197.031.207.022.211.023.221.007.221.008h.22l.219-.008.221-.045.176-.061.136-.083.106-.106.074-.107.06-.122.04-.12.031-.114.013-.221-.068-.19-.129-.152-.189-.128-.227-.114-.267-.114-.295-.107-.297-.121-.303-.136-.289-.159-.266-.198-.242-.235-.191-.289-.135-.342-.068-.409.007-.485.038-.297.052-.282.077-.257.091-.243.121-.227.137-.198.16-.19.19-.166.22-.144.243-.122.273-.114.305-.083.34-.07.373-.052.408-.031.449-.008h.279l.258.008.236.009.213.007.19.015.176.015.15.015.137.016.129.014.105.023.102.016.09.015.074.014.07.009.068.015.059.008-.387 1.714z"
        fill="#fff"
      />
      <path
        d="M52.572 38.602l-.105-.008-.108-.014-.097-.015-.098-.015-.092-.015-.097-.016-.1-.014-.107-.017-.106-.014-.121-.008-.137-.008-.142-.008h-.16l-.176.008-.197.016-.219.014-.153.024-.144.023-.129.045-.115.045-.106.053-.097.061-.077.077-.068.068-.047.083-.039.084-.014.09v.092l.024.099.029.098.061.099.076.099.152.143.184.114.211.099.228.083.243.077.251.083.25.092.243.098.228.129.211.159.192.191.158.235.121.288.076.341.031.411-.031.478-.053.357-.092.311-.121.288-.152.25-.174.221-.197.189-.213.167-.234.145-.243.114-.251.098-.258.076-.266.061-.258.046-.252.022-.25.016-.234.006h-.326l-.305-.006h-.266l-.25-.007-.228-.009-.205-.015-.182-.015-.174-.023-.16-.015-.144-.03-.143-.024-.131-.037-.129-.031-.121-.046-.121-.044-.129-.046.342-1.678.068.016.084.023.106.029.129.032.137.029.15.031.168.037.182.031.189.03.197.031.207.022.211.023.221.007.221.008h.22l.219-.008.221-.045.176-.061.136-.083.106-.106.074-.107.06-.122.04-.12.031-.114.013-.221-.068-.19-.129-.152-.189-.128-.227-.114-.267-.114-.295-.107-.297-.121-.303-.136-.289-.159-.266-.198-.242-.235-.191-.289-.135-.342-.068-.409.007-.485.038-.297.052-.282.077-.257.091-.243.121-.227.137-.198.16-.19.19-.166.22-.144.243-.122.273-.114.305-.083.34-.07.373-.052.408-.031.449-.008h.279l.258.008.236.009.213.007.19.015.176.015.15.015.137.016.129.014.105.023.102.016.09.015.074.014.07.009.068.015.059.008-.387 1.714z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M54.373 35.013h2.102l-.311 1.837h1.268l-.274 1.715-1.336-.038-.728 4.235-.008.152.037.13.076.098.1.083.137.054.152.045.166.03.182.016.168-.007.152-.016.135-.023.123-.03.097-.031.069-.022.045-.016.015-.007-.265 1.412-.053.038-.053.03-.055.03-.052.03-.053.023-.061.023-.068.023-.068.023-.076.022-.077.016-.089.015-.092.016-.1.015-.113.015-.121.015-.129.015h-.576l-.305-.023-.266-.03-.242-.045-.205-.054-.176-.069-.142-.074-.129-.092-.1-.099-.076-.106-.053-.129-.039-.13-.021-.143-.008-.16v-.167l.023-.174.022-.19 1.451-8.281z"
        fill="#fff"
      />
      <path
        d="M54.373 35.013h2.102l-.311 1.837h1.268l-.274 1.715-1.336-.038-.728 4.235-.008.152.037.13.076.098.1.083.137.054.152.045.166.03.182.016.168-.007.152-.016.135-.023.123-.03.097-.031.069-.022.045-.016.015-.007-.265 1.412-.053.038-.053.03-.055.03-.052.03-.053.023-.061.023-.068.023-.068.023-.076.022-.077.016-.089.015-.092.016-.1.015-.113.015-.121.015-.129.015h-.576l-.305-.023-.266-.03-.242-.045-.205-.054-.176-.069-.142-.074-.129-.092-.1-.099-.076-.106-.053-.129-.039-.13-.021-.143-.008-.16v-.167l.023-.174.022-.19 1.451-8.281z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M59.107 41.426l-.019.129-.008.136.012.145.031.144.055.145.06.138.082.143.092.128.108.13.113.115.121.113.137.09.134.085.155.067.15.053.152.031.2.03.187.023.197.023.2.015.195.008h.205l.199-.008.203-.015.205-.031.207-.038.211-.045.205-.068.213-.083.221-.093.211-.12.223-.129-.305 1.867-.121.06-.123.062-.121.044-.114.047-.113.037-.123.039-.121.029-.129.024-.137.023-.152.015-.158.015-.176.015-.197.008h-.205l-.235.007h-.25l-.373-.007-.379-.038-.378-.053-.381-.092-.371-.113-.358-.146-.342-.188-.318-.221-.297-.258-.258-.311-.218-.35-.184-.402-.129-.448-.084-.509-.021-.561.037-.622.031-.243.045-.282.06-.294.084-.321.116-.324.134-.328.176-.333.211-.319.244-.304.295-.288.334-.258.381-.228.439-.19.495-.137.546-.09.616-.032.295.008.312.031.309.052.312.076.305.115.295.137.273.182.25.212.229.258.189.305.153.348.105.403.061.454.007.516-.052.577-.114.639-.039.463h-5.533l.35-1.497h3.324l.016-.188-.008-.183-.016-.167-.029-.151-.055-.145-.053-.13-.076-.113-.076-.106-.09-.091-.099-.076-.114-.068-.115-.062-.119-.044-.123-.031-.129-.023-.135-.015-.146.008-.135.022-.129.032-.129.044-.131.061-.113.069-.115.083-.096.091-.099.106-.092.113-.082.123-.071.13-.068.136-.053.151-.045.152-.031.159-.305 1.571z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M77.213 37.305l-.1-.053-.097-.046-.106-.053-.115-.054-.113-.045-.129-.045-.129-.046-.137-.045-.144-.038-.145-.03-.16-.031-.158-.023-.174-.016-.176-.014h-.56l-.2.014-.203.024-.215.038-.211.06-.212.084-.211.106-.215.136-.205.168-.198.197-.189.243-.182.281-.168.333-.158.38-.142.427-.123.485-.045.272-.032.266-.015.266.008.258.023.258.053.243.068.228.084.227.113.206.137.196.16.175.182.16.205.137.234.113.258.099.291.068.098.022.113.023.137.016.152.015.166.016h.365l.19-.016.197-.023.197-.038.19-.053.189-.06.19-.084.175-.098.159-.122.152-.136-.342 2.027-.045.037-.047.046-.06.054-.068.043-.082.061-.108.054-.121.053-.137.045-.174.054-.197.038-.228.031-.266.03-.295.015-.342.008-.386-.008-.428-.023-.348-.038-.349-.076-.35-.113-.348-.153-.334-.181-.318-.229-.305-.265-.281-.303-.25-.342-.213-.379-.181-.425-.137-.464-.09-.508-.031-.539.015-.592.082-.631.063-.31.068-.304.074-.296.077-.281.084-.266.097-.265.1-.251.105-.235.116-.236.113-.22.129-.212.136-.197.137-.191.151-.181.152-.167.16-.16.174-.152.176-.144.181-.129.192-.122.203-.114.205-.097.213-.092.221-.084.234-.076.234-.06.244-.054.258-.045.258-.039.273-.029.274-.015.289-.009.242.009.237.015.226.023.229.03.212.03.206.045.195.039.183.045.174.045.153.046.144.046.121.045.106.038.092.031.068.03.047.016-.381 2.102z"
        fill="#fff"
      />
      <path
        d="M77.213 37.305l-.1-.053-.097-.046-.106-.053-.115-.054-.113-.045-.129-.045-.129-.046-.137-.045-.144-.038-.145-.03-.16-.031-.158-.023-.174-.016-.176-.014h-.56l-.2.014-.203.024-.215.038-.211.06-.212.084-.211.106-.215.136-.205.168-.198.197-.189.243-.182.281-.168.333-.158.38-.142.427-.123.485-.045.272-.032.266-.015.266.008.258.023.258.053.243.068.228.084.227.113.206.137.196.16.175.182.16.205.137.234.113.258.099.291.068.098.022.113.023.137.016.152.015.166.016h.365l.19-.016.197-.023.197-.038.19-.053.189-.06.19-.084.175-.098.159-.122.152-.136-.342 2.027-.045.037-.047.046-.06.054-.068.043-.082.061-.108.054-.121.053-.137.045-.174.054-.197.038-.228.031-.266.03-.295.015-.342.008-.386-.008-.428-.023-.348-.038-.349-.076-.35-.113-.348-.153-.334-.181-.318-.229-.305-.265-.281-.303-.25-.342-.213-.379-.181-.425-.137-.464-.09-.508-.031-.539.015-.592.082-.631.063-.31.068-.304.074-.296.077-.281.084-.266.097-.265.1-.251.105-.235.116-.236.113-.22.129-.212.136-.197.137-.191.151-.181.152-.167.16-.16.174-.152.176-.144.181-.129.192-.122.203-.114.205-.097.213-.092.221-.084.234-.076.234-.06.244-.054.258-.045.258-.039.273-.029.274-.015.289-.009.242.009.237.015.226.023.229.03.212.03.206.045.195.039.183.045.174.045.153.046.144.046.121.045.106.038.092.031.068.03.047.016-.381 2.102z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M95.9 44.979h-2.101l.152-.842v-.077l-.123.153-.127.135-.129.122-.136.107-.139.099-.137.082-.137.069-.144.069-.143.053-.144.038-.145.037-.152.022-.152.024-.145.015-.15.008h-.153l-.394-.031-.363-.083-.334-.129-.305-.174-.274-.221-.242-.257-.213-.282-.181-.318-.153-.343-.121-.355-.084-.372-.06-.388-.022-.387.006-.394.039-.388.069-.386.113-.45.138-.424.157-.387.191-.364.197-.342.227-.304.236-.272.25-.251.266-.221.281-.189.289-.167.287-.129.303-.107.305-.068.304-.045.311-.015.221.006.211.024.199.038.183.053.172.061.153.067.152.085.137.083.121.09.113.1.098.098.092.099.076.099.07.098.059.092.046.091.532-2.983h2.064L95.9 44.979z"
        fill="#fff"
      />
      <path
        d="M95.9 44.979h-2.101l.152-.842v-.077l-.123.153-.127.135-.129.122-.136.107-.139.099-.137.082-.137.069-.144.069-.143.053-.144.038-.145.037-.152.022-.152.024-.145.015-.15.008h-.153l-.394-.031-.363-.083-.334-.129-.305-.174-.274-.221-.242-.257-.213-.282-.181-.318-.153-.343-.121-.355-.084-.372-.06-.388-.022-.387.006-.394.039-.388.069-.386.113-.45.138-.424.157-.387.191-.364.197-.342.227-.304.236-.272.25-.251.266-.221.281-.189.289-.167.287-.129.303-.107.305-.068.304-.045.311-.015.221.006.211.024.199.038.183.053.172.061.153.067.152.085.137.083.121.09.113.1.098.098.092.099.076.099.07.098.059.092.046.091.532-2.983h2.064L95.9 44.979z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M92.463 43.142l.213-.015.197-.045.182-.053.175-.084.159-.09.152-.116.143-.127.13-.139.114-.151.105-.167.1-.175.084-.181.074-.191.063-.197.052-.205.037-.204.037-.212.026-.22v-.207l-.01-.211-.021-.198-.032-.19-.052-.182-.061-.175-.076-.15-.09-.145-.1-.123-.113-.105-.129-.091-.138-.061-.143-.038-.16-.016-.205.016-.19.03-.173.061-.176.076-.153.099-.15.121-.129.129-.129.145-.115.165-.106.176-.099.182-.084.197-.074.205-.061.206-.06.219-.047.221-.039.334.017.364.053.371.098.357.152.312.197.25.25.168.305.06z"
        fill="#fbb231"
      />
      <path
        d="M45.848 43.294l-.305 1.685h-1.912l.053-.311.039-.281.029-.212.031-.115-.105.122-.123.122-.143.114-.16.105-.182.107-.191.09-.197.092-.203.076-.221.068-.221.054-.219.045-.228.03-.221.016-.219.007-.212-.023-.208-.03-.265-.068-.242-.077-.213-.098-.19-.114-.166-.121-.152-.137-.121-.145-.108-.151-.089-.159-.077-.168-.06-.166-.045-.167-.031-.175-.024-.175-.015-.167V42.8l.023-.296.047-.281.074-.265.1-.259.121-.242.137-.22.16-.212.181-.191.19-.174.213-.16.218-.144.229-.122.242-.104.252-.085.258-.068.266-.046h.015l.037-.008.053-.007.084-.008.092-.015.105-.008.114-.015.13-.015.129-.014.129-.016.137-.008.121-.015.121-.007.113-.009.106-.006h.26l.183.006.166.009h.166l.137.007.107.007.075.008h.023l.016-.053.045-.136.039-.161.013-.143-.029-.167-.068-.153-.092-.136-.121-.114-.145-.097-.158-.069-.183-.046-.198-.015-.213-.008-.228-.007h-.235l-.242.007-.25.016-.244.022-.242.023-.236.031-.227.029-.221.038-.197.038-.182.038-.168.047-.134.044-.108.054-.084.045.539-1.829.168-.068.166-.061.184-.053.182-.039.181-.038.197-.029.198-.031.205-.016.213-.014.218-.008.229-.008h.729l.265.008.266.008.213.014.228.039.236.053.241.069.234.091.231.106.226.129.213.144.189.168.168.181.153.205.113.228.076.244.045.257v.288l-.045.304-.767 4.008z"
        fill="#fff"
      />
      <path
        d="M45.848 43.294l-.305 1.685h-1.912l.053-.311.039-.281.029-.212.031-.115-.105.122-.123.122-.143.114-.16.105-.182.107-.191.09-.197.092-.203.076-.221.068-.221.054-.219.045-.228.03-.221.016-.219.007-.212-.023-.208-.03-.265-.068-.242-.077-.213-.098-.19-.114-.166-.121-.152-.137-.121-.145-.108-.151-.089-.159-.077-.168-.06-.166-.045-.167-.031-.175-.024-.175-.015-.167V42.8l.023-.296.047-.281.074-.265.1-.259.121-.242.137-.22.16-.212.181-.191.19-.174.213-.16.218-.144.229-.122.242-.104.252-.085.258-.068.266-.046h.015l.037-.008.053-.007.084-.008.092-.015.105-.008.114-.015.13-.015.129-.014.129-.016.137-.008.121-.015.121-.007.113-.009.106-.006h.26l.183.006.166.009h.166l.137.007.107.007.075.008h.023l.016-.053.045-.136.039-.161.013-.143-.029-.167-.068-.153-.092-.136-.121-.114-.145-.097-.158-.069-.183-.046-.198-.015-.213-.008-.228-.007h-.235l-.242.007-.25.016-.244.022-.242.023-.236.031-.227.029-.221.038-.197.038-.182.038-.168.047-.134.044-.108.054-.084.045.539-1.829.168-.068.166-.061.184-.053.182-.039.181-.038.197-.029.198-.031.205-.016.213-.014.218-.008.229-.008h.729l.265.008.266.008.213.014.228.039.236.053.241.069.234.091.231.106.226.129.213.144.189.168.168.181.153.205.113.228.076.244.045.257v.288l-.045.304-.767 4.008z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M44.02 41.312l-.008-.037-.032-.038-.044-.023-.077-.015-.084-.015-.097-.008h-.229l-.123.008-.129.015-.121.015-.121.016-.113.015-.108.022-.089.023-.084.022-.053.015-.061.024-.084.03-.084.038-.097.046-.1.053-.105.053-.1.061-.106.075-.097.076-.092.092-.084.091-.076.098-.053.107-.047.121-.021.121-.008.259.039.204.074.153.1.113.113.076.129.045.121.023.123.008.229-.008.205-.023.197-.031.172-.045.154-.053.143-.067.129-.069.115-.084.1-.076.089-.091.077-.083.06-.091.053-.091.045-.091.039-.084.021-.084.063-.219.053-.251.044-.243.04-.198z"
        fill="#ec1c2e"
      />
      <path
        d="M29.877 44.979h-2.064l1.722-9.966h3.592l.34 5.913 2.482-5.913h3.75l-1.761 9.966h-2.065l1.305-7.446h-.082l-3.09 7.446h-2.33l-.014-.311-.055-.828-.068-1.176-.076-1.352-.076-1.358-.069-1.191-.052-.865-.016-.365h-.113l-1.26 7.446z"
        fill="#fff"
      />
      <path
        d="M29.877 44.979h-2.064l1.722-9.966h3.592l.34 5.913 2.482-5.913h3.75l-1.761 9.966h-2.065l1.305-7.446h-.082l-3.09 7.446h-2.33l-.014-.311-.055-.828-.068-1.176-.076-1.352-.076-1.358-.069-1.191-.052-.865-.016-.365h-.113l-1.26 7.446z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M83.521 43.294l-.31 1.685h-1.906l.015-.311.008-.273v-.205l.016-.091-.1.114-.115.113-.137.106-.15.108-.16.097-.182.091-.189.092-.19.076-.207.06-.211.06-.213.039-.211.03-.222.016-.211.007-.207-.023-.203-.03-.274-.068-.242-.077-.213-.098-.197-.114-.168-.121-.145-.137-.121-.145-.107-.151-.09-.159-.068-.168-.061-.166-.037-.167-.031-.175-.024-.175-.015-.167V42.8l.015-.296.047-.281.068-.265.098-.259.113-.242.147-.22.158-.212.174-.191.199-.174.203-.16.221-.144.234-.122.237-.104.25-.085.25-.068.257-.046h.016l.039-.008.053-.007.076-.008.098-.015.107-.008.113-.015.122-.015.128-.014.137-.016.129-.008.131-.015.121-.007.105-.009.106-.006h.266l.183.006.174.009h.168l.143.007.123.007.068.008h.031l.014-.053.023-.136.022-.161.015-.143-.031-.167-.058-.153-.1-.136-.115-.114-.135-.097-.16-.069-.168-.046-.182-.015-.213-.008-.226-.007H80.4l-.242.007-.252.016-.242.022-.242.023-.236.031-.227.029-.221.038-.195.038-.184.038-.168.047-.136.044-.106.054-.084.045.533-1.829.166-.068.174-.061.174-.053.184-.039.189-.038.19-.029.197-.031.205-.016.213-.014.221-.008.218-.008h.715l.516.016.213.014.228.039.235.053.244.069.234.091.234.106.229.129.213.144.189.168.176.181.15.205.114.228.084.244.052.257v.288l-.037.304-.797 4.008z"
        fill="#fff"
      />
      <path
        d="M83.211 45.032l.045-.045.31-1.686-.091-.015-.311 1.686.047-.046v.106l.037-.008.008-.037-.045.045z"
        fill="#fff"
      />
      <path
        d="M81.258 44.979l.047.053h1.906v-.106h-1.906l.045.053h-.092l-.006.053h.053l-.047-.053z"
        fill="#fff"
      />
      <path
        d="M81.381 44.128l-.068-.06-.032.122-.008.205v.273l-.015.311h.092l.015-.311.016-.273-.01-.205v-.062l-.066-.06.076.06z"
        fill="#fff"
      />
      <path
        d="M78.428 45.101l.211.03.207.022.211-.006.222-.016.219-.03.213-.039.211-.059.213-.062.189-.076.197-.09.182-.091.16-.107.16-.107.137-.105.121-.123.1-.114-.076-.06-.1.114-.105.106-.137.107-.145.106-.16.091-.181.092-.182.091-.19.075-.199.06-.211.062-.213.038-.203.029-.222.016-.211.007-.207-.023-.198-.029-.013.091z"
        fill="#fff"
      />
      <path
        d="M76.59 42.8l.008.167.015.167.022.183.031.174.039.167.061.175.068.174.098.16.107.159.121.144.153.144.173.122.198.122.22.098.248.076.276.069.013-.091-.271-.069-.234-.076-.207-.098-.198-.107-.16-.12-.137-.131-.121-.144-.105-.144-.082-.159-.071-.16-.06-.158-.037-.168-.031-.175-.022-.167-.016-.167.008-.167h-.107zM79.314 39.885l-.263.046-.258.069-.25.083-.244.106-.236.121-.229.153-.203.16-.197.181-.184.19-.158.212-.153.228-.113.251-.099.258-.069.273-.045.288-.023.296h.107l.008-.296.045-.273.068-.258.098-.258.115-.236.137-.212.16-.213.166-.189.195-.167.207-.16.211-.136.237-.122.226-.105.252-.084.242-.069.25-.045v-.092zM80.879 39.726h-.084l-.106.016-.105.009-.121.006-.131.014-.129.009-.137.015-.128.014-.122.016-.113.016-.107.006-.098.016-.076.007-.061.008-.031.007h-.016v.092h.016l.047-.009.045-.006.076-.008.098-.015.107-.008.113-.015.122-.016.128-.015.137-.015.129-.007.131-.016.121-.006.105-.009h.19v-.106z"
        fill="#fff"
      />
      <path
        d="M81.904 39.809l.047-.038h-.031l-.068-.007-.123-.007-.143-.015H81.244l-.183-.016h-.182v.106h.365l.174.015h.311l.123.008.068.008h.031l.043-.038-.043.038.037.007.006-.045-.09-.016z"
        fill="#fff"
      />
      <path
        d="M81.98 39.324h-.007l-.008.143-.024.153-.021.137-.016.052.09.016.016-.054.023-.137.024-.167.023-.143H81.98z"
        fill="#fff"
      />
      <path
        d="M81.076 38.572v.008l.176.008.158.046.153.067.128.092.106.106.092.129.062.136.029.16h.09l-.029-.174-.061-.168-.107-.143-.121-.123-.145-.105-.168-.069-.173-.045-.19-.023v.098zM77.82 38.937l.076.045.077-.038.097-.053.129-.046.168-.044.182-.038.197-.039.221-.038.219-.029.236-.032.242-.023.242-.022.252-.015h.705l.213.007v-.091l-.213-.007-.226-.016H80.4l-.242.016-.252.014-.242.024-.242.023-.236.03-.235.029-.221.04-.197.038-.181.038-.166.044-.145.047-.115.052-.092.053.078.046-.092-.015z"
        fill="#fff"
      />
      <path
        d="M78.383 37.069l-.031.039-.532 1.829.092.015.529-1.83-.029.039-.029-.092-.024.008-.007.031.031-.039z"
        fill="#fff"
      />
      <path
        d="M81.76 36.721l-.258-.015-.258-.009h-.715l-.218.016-.221.008-.213.016-.205.013-.205.03-.188.031-.191.039-.184.038-.181.053-.174.06-.166.068.029.092.168-.069.174-.06.166-.054.184-.037.189-.039.189-.03.19-.03.205-.015.213-.016.221-.007h.933l.258.007h.258v-.09zM84.363 39.294l.039-.312v-.288l-.052-.267-.084-.25-.121-.234-.153-.213-.183-.189-.19-.168-.219-.145-.228-.136-.242-.106-.237-.092-.25-.067-.236-.053-.234-.037-.213-.016v.09l.213.016.218.038.237.053.236.068.234.091.227.107.229.121.205.144.189.166.166.176.152.196.108.222.084.235.053.25v.288l-.04.297.092.015zM83.566 43.301l.797-4.007-.092-.015-.796 4.007.091.015z"
        fill="#fff"
      />
      <path
        d="M81.684 41.312l-.016-.045-.029-.037-.053-.024-.078-.022-.082-.008-.098-.008h-.115l-.113.008-.123.015-.12.015-.121.016-.115.015-.113.023-.1.015-.097.023-.077.014-.052.008-.061.023-.076.023-.084.03-.1.045-.091.047-.106.053-.098.067-.099.069-.098.083-.084.083-.084.1-.066.106-.055.113-.045.122-.023.129-.008.259.037.204.063.153.089.113.114.076.131.045.128.023.129.008.227-.008.213-.023.197-.038.176-.052.166-.061.144-.068.137-.077.121-.091.108-.083.089-.098.084-.092.069-.099.053-.091.037-.091.031-.091.023-.083.03-.213.039-.22.029-.212.016-.191z"
        fill="#fbb231"
      />
      <path
        d="M97.32 43.94l.016-.176.06-.205.108-.182.129-.159.158-.128.182-.092.197-.062.213-.022.215.022.195.062.184.092.158.128.129.159.105.182.063.205.015.176h-.228l-.008-.138-.045-.167-.076-.151-.1-.13-.121-.106-.142-.075-.168-.054-.176-.015-.174.015-.166.054-.147.075-.121.106-.097.13-.076.151-.045.167-.016.138h-.221z"
        fill="#fff"
      />
      <path
        d="M98.004 43.94v-.456h.418l.092.007.084.015.058.023.055.03.037.046.029.046.016.052.008.054-.008.068-.016.053-.021.046-.016.016h-.318l.029-.009.053-.007.047-.015.039-.015.029-.023.016-.024.015-.036v-.054l-.009-.038-.014-.031-.025-.014-.022-.016-.037-.007-.039-.007h-.311v.296h-.189zM97.32 43.94l-.006.045.022.22.06.204.108.183.129.152.158.128.182.1.197.06.213.023.215-.023.195-.06.184-.1.158-.128.129-.152.105-.183.063-.204.023-.22-.008-.045h-.228l.008.045-.016.182-.045.167-.076.145-.1.127-.121.108-.142.082-.168.054-.176.015-.174-.015-.166-.054-.147-.082-.121-.108-.097-.127-.076-.145-.045-.167-.016-.182v-.045h-.221z"
        fill="#fff"
      />
      <path
        d="M98.004 43.94v.463h.189v-.38h.19l.228.38h.151l-.227-.38h.055l.051-.016.047-.015.039-.03.013-.022h-.318l-.039.007h-.19v-.007h-.189z"
        fill="#fff"
      />
      <path
        d="M98.383 44.865l.176-.015.168-.054.142-.082.121-.108.1-.127.076-.145.045-.167.016-.182-.016-.183-.045-.167-.076-.151-.1-.13-.121-.106-.142-.075-.168-.054-.176-.015-.174.015-.166.054-.147.075-.121.106-.097.13-.076.151-.045.167-.016.183.016.182.045.167.076.145.097.127.121.108.147.082.166.054.174.015zm0-.918l.068-.016.053-.007.047-.015.039-.015.029-.023.016-.024.015-.036v-.054l-.009-.038-.014-.031-.025-.014-.022-.016-.037-.007-.039-.007h-.311v.303h.19zm-.19.456h-.189v-.919h.418l.092.007.084.015.058.023.055.03.037.046.029.046.016.052.008.054-.008.068-.016.053-.021.046-.029.038-.039.03-.047.015-.051.016h-.055l.227.38h-.151l-.228-.38h-.19v.38zm-.879-.418l.022-.221.06-.205.108-.182.129-.159.158-.128.182-.092.197-.062.213-.022.215.022.195.062.184.092.158.128.129.159.105.182.063.205.023.221-.023.22-.063.204-.105.183-.129.152-.158.128-.184.1-.195.06-.215.023-.213-.023-.197-.06-.182-.1-.158-.128-.129-.152-.108-.183-.06-.204-.022-.22z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M84.09 44.979h2.14l.768-4.319.029-.166.045-.168.047-.167.061-.167.076-.167.09-.16.099-.151.123-.145.135-.128.152-.122.174-.115.198-.091.22-.074.242-.061.266-.046.297-.016h.189l.342-1.95-.213.014-.205.024-.189.038-.182.052-.168.061-.158.077-.152.083-.143.091-.139.107-.129.106-.121.122-.121.12-.115.13-.111.143-.116.137-.107.152.229-1.373H85.51l-1.42 8.129z"
        fill="#fff"
      />
      <path
        d="M84.09 44.979h2.14l.768-4.319.029-.166.045-.168.047-.167.061-.167.076-.167.09-.16.099-.151.123-.145.135-.128.152-.122.174-.115.198-.091.22-.074.242-.061.266-.046.297-.016h.189l.342-1.95-.213.014-.205.024-.189.038-.182.052-.168.061-.158.077-.152.083-.143.091-.139.107-.129.106-.121.122-.121.12-.115.13-.111.143-.116.137-.107.152.229-1.373H85.51l-1.42 8.129z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M63.99 44.979h2.178l.728-4.319.032-.166.037-.16.039-.159.053-.153.06-.152.076-.144.09-.136.108-.129.121-.116.136-.112.166-.092.184-.083.213-.069.234-.059.258-.039.297-.023h.021l.032.008.037.008h.039l.039.007.029.008.022.007h.008l.009-.03.022-.091.029-.136.039-.168.053-.197.053-.213.07-.212.066-.214.063-.143.076-.145.074-.143.076-.129.069-.114.053-.091.037-.053.015-.023h-.008l-.029-.008-.039-.008-.053-.008h-.052l-.061-.006-.053-.008h-.052l-.237.014-.211.024-.199.038-.182.046-.175.06-.159.077-.144.074-.137.091-.129.1-.121.114-.113.114-.115.127-.114.131-.107.145-.113.15-.114.152.274-1.373H65.41l-1.42 8.129z"
        fill="#fff"
      />
      <path
        d="M63.99 44.979h2.178l.728-4.319.032-.166.037-.16.039-.159.053-.153.06-.152.076-.144.09-.136.108-.129.121-.116.136-.112.166-.092.184-.083.213-.069.234-.059.258-.039.297-.023h.021l.032.008.037.008h.039l.039.007.029.008.022.007h.008l.009-.03.022-.091.029-.136.039-.168.053-.197.053-.213.07-.212.066-.214.063-.143.076-.145.074-.143.076-.129.069-.114.053-.091.037-.053.015-.023h-.008l-.029-.008-.039-.008-.053-.008h-.052l-.061-.006-.053-.008h-.052l-.237.014-.211.024-.199.038-.182.046-.175.06-.159.077-.144.074-.137.091-.129.1-.121.114-.113.114-.115.127-.114.131-.107.145-.113.15-.114.152.274-1.373H65.41l-1.42 8.129z"
        fill="none"
        stroke="#fff"
        strokeWidth=".22px"
      />
      <path
        d="M97.336 52.965v-.031l.06-.197.108-.175.129-.151.158-.122.182-.092.197-.052.213-.023.215.023.195.052.184.092.158.122.129.151.105.175.063.197v.031h-.221l-.045-.159-.076-.145-.1-.13-.121-.105-.142-.084-.168-.053-.176-.015-.174.015-.166.053-.147.084-.121.105-.097.13-.076.145-.045.159h-.221z"
        fill="#fbb231"
      />
      <path
        d="M98.004 52.965V52.7h.51l.084.015.058.013.055.031.037.039.029.045.016.053.008.069h-.151v-.039l-.009-.038-.014-.03-.025-.015-.022-.015-.037-.008-.039-.007h-.311v.152h-.189zM97.314 53.131l.022-.166h.221v.006l-.016.16h-.227zM98.004 53.131v-.166h.189v.151h.19l.068-.014.053-.008.047-.016.039-.015.029-.022.016-.024.015-.036v-.016h.151l-.008.052-.016.046-.021.039-.029.029h-.723zM99.227 53.131l-.016-.16v-.006h.221l.023.166h-.228z"
        fill="#fbb231"
      />
      <path
        d="M97.314 53.131v.023l.022.22.06.205.108.182.129.16.158.13.182.089.197.062.213.022.215-.022.195-.062.184-.089.158-.13.129-.16.105-.182.063-.205.023-.22v-.023h-.228v.023l-.016.183-.045.167-.076.143-.1.128-.121.109-.142.083-.168.053-.176.015-.174-.015-.166-.053-.147-.083-.121-.109-.097-.128-.076-.143-.045-.167-.016-.183v-.023h-.227z"
        fill="#fbb231"
      />
      <path
        d="M98.004 53.131v.441h.189v-.38h.19l.228.38h.151l-.227-.38h.055l.051-.015.047-.015.039-.031h-.723z"
        fill="#fbb231"
      />
      <path
        d="M62.168 39.968h-2.773l.001-.006-.003-.001.019-.106.031-.159.045-.152.053-.151.068-.136.071-.13.082-.123.092-.113.099-.106.096-.091.115-.083.113-.069.131-.061.129-.044.129-.032.135-.022.146-.008.135.015.129.023.123.031.119.044.115.062.114.068.099.076.09.091.076.106.076.113.053.13.055.145.029.151.016.167.008.183-.016.188zm2.057-.698l-.061-.454-.105-.403-.153-.348-.189-.305-.229-.258-.25-.212-.273-.182-.295-.137-.305-.115-.312-.076-.309-.052-.312-.031-.295-.008-.616.032-.546.09-.495.137-.439.19-.381.228-.334.258-.295.288-.244.304-.211.319-.176.333-.134.328-.116.324-.084.321-.06.294-.045.282-.031.243-.037.622.021.561.084.509.129.448.184.402.218.35.258.311.297.258.318.221.342.188.358.146.371.113.381.092.378.053.379.038.373.007h.25l.235-.007h.205l.197-.008.176-.015.158-.015.152-.015.137-.023.129-.024.121-.029.123-.039.113-.037.114-.047.121-.044.123-.062.121-.06.305-1.867-.223.129-.211.12-.221.093-.213.083-.205.068-.211.045-.207.038-.205.031-.203.015-.199.008h-.205l-.195-.008-.2-.015-.197-.023-.187-.023-.2-.03-.152-.031-.15-.053-.155-.067-.134-.085-.137-.09-.121-.113-.113-.115-.108-.13-.092-.128-.082-.143-.06-.138-.055-.145-.031-.144-.012-.145.006-.128.018-.098h4.923l.039-.463.114-.639.052-.577-.007-.516z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;

import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { paymentValidationSchema } from "helpers/validation";

const PaymentForm = ({ submitIt }) => (
  // console.log("initVals: ", initVals);

  <Formik
    initialValues={{
      email: "",
      namefirst: "",
      namelast: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      ccnumber: "",
      ccexpmonth: "",
      ccexpyear: "",
      cccode: "",
      agreement: false,
      agreement2: false,
    }}
    validationSchema={paymentValidationSchema}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      submitIt(values, actions);
    }}
  >
    {props => (
      <Form className="form">
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <Field
                name="email"
                placeholder="Your Email (for receipts)"
                className="form-control"
                required
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="namefirst">First Name:</label>
              <Field
                name="namefirst"
                placeholder="Name as it appears on card."
                className="form-control"
                required
              />
              <ErrorMessage
                name="namefirst"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="namelast">Last Name:</label>
              <Field
                name="namelast"
                placeholder="Name as it appears on card."
                className="form-control"
                required
              />
              <ErrorMessage
                name="namelast"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="address">Street Address:</label>
              <Field
                name="address"
                placeholder="Cardholder street address"
                className="form-control"
                required
              />
              <ErrorMessage
                name="address"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="city">City:</label>
              <Field
                name="city"
                className="form-control"
                placeholder="Start Time"
                required
              />
              <ErrorMessage
                name="city"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="state">State:</label>
              <Field
                name="state"
                className="form-control"
                placeholder="State"
                required
              />
              <ErrorMessage
                name="state"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="zip">Zip:</label>
              <Field
                name="zip"
                className="form-control"
                placeholder="Zip Code"
                required
              />
              <ErrorMessage
                name="zip"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="ccnumber">Card Number:</label>
              <Field name="ccnumber" className="form-control" required />
              <ErrorMessage
                name="ccnumber"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="ccexpmonth">Card Exp. Month:</label>
              <Field
                as="select"
                name="ccexpmonth"
                className="form-control"
                required
              >
                <option value="">Select Month</option>
                <option value={1}>Jan</option>
                <option value={2}>Feb</option>
                <option value={3}>Mar</option>
                <option value={4}>Apr</option>
                <option value={5}>May</option>
                <option value={6}>Jun</option>
                <option value={7}>Jul</option>
                <option value={8}>Aug</option>
                <option value={9}>Sep</option>
                <option value={10}>Oct</option>
                <option value={11}>Nov</option>
                <option value={12}>Dec</option>
              </Field>
              <ErrorMessage
                name="ccexpmonth"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="ccexpyear">Card Exp. Year:</label>
              <Field
                name="ccexpyear"
                className="form-control"
                required
                as="select"
              >
                <option value="">Select Year</option>
                <option value={2020}>2020</option>
                <option value={2021}>2021</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
                <option value={2025}>2025</option>
                <option value={2026}>2026</option>
                <option value={2027}>2027</option>
                <option value={2028}>2028</option>
                <option value={2029}>2029</option>
              </Field>
              <ErrorMessage
                name="ccexpyear"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="cccode">Card Code:</label>
              <Field name="cccode" className="form-control" required />
              <ErrorMessage
                name="cccode"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <hr />

        <div className="form-row">
          <div className="col-12">
            <p className="text-danger">
              You must read and indicate your agreement with the statements
              below by checking the boxes and then clicking "Complete Purchase"
              to finalize this order.
            </p>

            <p className="font-weight-bold" />

            <div className="form-check">
              <Field
                name="agreement"
                type="checkbox"
                className="form-check-input"
                required
              />
              <label htmlFor="agreement" className="form-check-label">
                I have reviewed the items in my cart, above, and confirm that
                these are the items that I wish to purchase.
              </label>

              <ErrorMessage
                name="agreement"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-check">
              <Field
                name="agreement2"
                type="checkbox"
                className="form-check-input"
                required
              />
              <label htmlFor="agreement2" className="form-check-label">
                I understand that ALL SALES ARE FINAL, regardless of
                circumstances, and I agree to pay all fees identified above.
              </label>

              <ErrorMessage
                name="agreement2"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <hr />

        <button
          type="submit"
          className="btn btn-primary btn-lg mt-3"
          disabled={props.isSubmitting}
        >
          Complete Purchase
        </button>
      </Form>
    )}
  </Formik>
);

export default PaymentForm;

import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { navigate } from "@reach/router";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";

import PaymentForm from "components/forms/PaymentForm";
import Layout from "components/Layout";
import SEO from "components/Seo";
import CartTotalItem from "components/cart/CartTotalItem";
import CreditCardIcons from "components/CreditCardIcons";

import { cartContext } from "providers/CartProvider";
import { VA_TAX_RATE } from "helpers";

import { StyledLineItemsHolder } from "components/cart/cartStyles";

const checkOutdatedClasses = data => {
  const outdatedChecks = data
    .map(cl => {
      const rawDate = Date.parse(cl.date);

      const [startHours, startMinutes] = cl.timestart.split(":");
      let adjustedStartDate = addHours(rawDate, startHours);
      adjustedStartDate = addMinutes(adjustedStartDate, startMinutes);

      if (adjustedStartDate < Date.now()) {
        // removeFn(cl.id);
        return true;
      }

      return null;
    })
    .filter(x => x);
  return outdatedChecks.length;
};

const CheckoutPage = ({ location }) => {
  const siteContext = useContext(cartContext);
  const [cartDetails, setCartDetails] = useState([]);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartTax, setCartTax] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartFee, setCartFee] = useState(0);

  useEffect(() => {
    async function fetchDetails() {
      const cart = location.state.cartDetails;
      setCartDetails(cart);
    }

    fetchDetails();
  }, [location]);

  useEffect(() => {
    function getTotal() {
      if (cartDetails.length > 0) {
        let subtotal = cartDetails.reduce(
          (acc, curr) => acc + Number(curr.price) * curr.qty,
          0
        );

        subtotal = +Number(subtotal).toFixed(2);
        const tax = +Number(subtotal * VA_TAX_RATE).toFixed(2);
        const fee = +Number((subtotal + tax) * 0.03).toFixed(2);
        const total = +Number(fee + tax + subtotal).toFixed(2);

        setCartSubTotal(subtotal);
        setCartTax(tax);
        setCartFee(fee);
        setCartTotal(total);
      } else {
        setCartSubTotal(0);
        setCartTax(0);
        setCartTotal(0);
        setCartFee(0);
      }
    }

    getTotal();
  }, [cartDetails]);

  const handleSubmit = async (values, actions) => {
    const outdatedCount = checkOutdatedClasses(cartDetails);
    if (outdatedCount > 0) {
      return siteContext.makeToast(
        "One or more of your classes has already started! You must remove the class(es) from your cart before you can checkout.",
        "error"
      );
    }

    try {
      // adjust values to include cart items
      const valuesWithCart = {
        ...values,
        cart: JSON.stringify(siteContext.cart),
      };
      // console.log("handleSubmit -> valuesWithCart", valuesWithCart);

      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/shop/checkout`,
        valuesWithCart,
        {
          headers: {
            Authorization: `Bearer ${siteContext.userToken}`,
          },
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response.status === 200) {
        // console.log(response.data.message);
        setCartDetails([]);
        actions.setSubmitting(false);
        actions.resetForm();
        siteContext.emptyCart();
        siteContext.makeToast(
          "Purchase successful! Please check your email.",
          "success"
        );
        // TODO: Remove this after shop success
        return navigate("/success/");
        // return null;
      }

      // console.log("response: ", response);
      actions.setSubmitting(false);
      return siteContext.makeToast(response.data, "error");
    } catch (e) {
      actions.setSubmitting(false);
      siteContext.makeToast("There was a problem with your purchase.", "error");
    }
  };

  return (
    <Layout>
      <SEO title="Cart" />
      <section>
        <Container>
          <h2>Checkout:</h2>
          <Row>
            <Col xs={{ span: 12, order: 6 }} md={{ span: 6, order: 1 }}>
              <h5>Cardholder Info:</h5>
              <hr />
              <PaymentForm submitIt={handleSubmit} />

              <div className="cards-accepted mt-4">
                <h6>Payments Accepted:</h6>
                <CreditCardIcons />
              </div>
            </Col>

            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 6 }}>
              <StyledLineItemsHolder>
                <ul>
                  {cartDetails.map((item, i) => (
                    <li key={i}>
                      <CartTotalItem siteContext={siteContext} item={item} />
                    </li>
                  ))}
                </ul>

                <div className="cart-total">
                  <p>Subtotal: ${Number(cartSubTotal).toFixed(2)}</p>
                  <p>Tax: ${Number(cartTax).toFixed(2)}</p>
                  <p>3% Processing Fee: ${Number(cartFee).toFixed(2)}</p>
                  <p className="total">
                    Total: ${Number(cartTotal).toFixed(2)}
                  </p>
                </div>
              </StyledLineItemsHolder>

              <hr />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default CheckoutPage;

import React from "react";

function AmexComponent(props) {
  return (
    <svg
      viewBox="0 0 126 79"
      xmlns="https://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={1.41421}
      {...props}
    >
      <g fillRule="nonzero">
        <path
          d="M125.984 74.669a4.18 4.18 0 01-4.181 4.179H4.734a4.179 4.179 0 01-4.179-4.179V4.635A4.179 4.179 0 014.734.456h117.069a4.18 4.18 0 014.181 4.179v70.034z"
          fill="#0078a8"
        />
        <path
          d="M39.471 37.827v-1.254l-.6 1.254h-5.193l-.653-1.346v1.346h-9.707l-1.146-2.715h-1.945l-1.215 2.715h-5.033v-8.195l3.689-8.348h7.064l.993 2.365v-2.348h8.638l1.901 3.983 1.859-4h27.273s1.43-.02 2.372.642l-.006-.642h6.871v.904s1.236-.904 3.062-.904H89.82l1.065 2.4v-2.4h7.769l1.41 2.4v-2.4h7.428v16.558h-7.631l-1.793-2.886v2.871h-9.484L87.25 34.87h-1.859l-1.25 2.957H78.4s-2.14-.027-3.746-1.176v1.176H63.227v-3.863c0-.561-.43-.522-.43-.522h-.418v4.385H39.471zM33.768 41.413h15.015l2.168 2.453 2.217-2.453h11.545s1.084.027 2.113.576v-.576h10.281c1.305 0 2.401.652 2.401.652v-.652h13.215v.601s.941-.601 2.207-.601h8.609v.601s1.084-.601 2.231-.601h6.759v15.603s-.888 1.006-2.726 1.006h-8.912v-.496s-.848.496-2.141.496H75.076v-4.033c0-.397-.172-.469-.525-.469h-.326v4.502h-7.399v-4.474s-.82.441-2.191.441h-2.66v4.033h-9.12l-2.074-2.531-2.218 2.531H33.768V41.413z"
          fill="#fff"
        />
        <path
          d="M81.094 43.903h9.424v2.527h-6.539v2.204h6.355v2.226h-6.355v2.318h6.539v2.465h-9.424v-11.74zM108.723 48.477c3.541.182 3.838 1.951 3.838 3.838 0 2.199-1.809 3.381-3.696 3.381h-6.054v-2.518h4.716c.801 0 1.993 0 1.993-1.127 0-.535-.209-.876-1.061-.966-.379-.055-1.779-.133-1.992-.137-3.199-.084-3.946-1.676-3.946-3.613 0-2.201 1.547-3.405 3.436-3.405h6.053v2.516h-4.43c-1.008.01-2.096-.119-2.096 1.02 0 .72.537.865 1.219.931.236 0 1.809.082 2.02.08zM97.869 48.477c3.543.182 3.84 1.951 3.84 3.838 0 2.199-1.807 3.381-3.695 3.381h-6.053v-2.518h4.717c.799 0 1.992 0 1.992-1.127 0-.535-.211-.876-1.063-.966-.378-.055-1.781-.133-1.99-.137-3.203-.084-3.943-1.676-3.943-3.613 0-2.201 1.545-3.405 3.431-3.405h6.053v2.516H96.73c-1.009.01-2.097-.119-2.097 1.02 0 .72.539.865 1.221.931.234 0 1.806.082 2.015.08zM62.723 49.266h-3.59v-2.818h3.601c1.207 0 1.588.773 1.588 1.35 0 .787-.42 1.468-1.599 1.468zm-6.498 5.276l-4.141-4.901 4.141-4.586v9.487zm-11.018-1.364h-6.646V50.86h6.357v-2.226h-6.357V46.43l7.009.016 2.807 3.195-3.17 3.537zm18.486-9.25H53.578l-3.303 3.643-3.195-3.668H35.719v11.74h10.992l3.461-3.879 3.379 3.907h5.531v-3.987h3.877c1.494 0 4.221-.05 4.221-4.166 0-2.935-2.071-3.59-3.487-3.59zM75.129 49.108h-3.59v-2.66h3.602c1.205 0 1.586.773 1.586 1.35 0 .785-.418 1.31-1.598 1.31zm2.488 1.215c1.574-.785 1.992-1.834 1.992-3.25 0-2.514-2.043-3.143-3.459-3.143h-7.494v11.741h2.883v-4.143h3.774c.996 0 1.388.996 1.414 1.992l.076 2.151h2.806l-.105-2.412c0-1.889-.522-2.78-1.887-2.936z"
          fill="#0078a8"
        />
        <path
          fill="#0078a8"
          d="M69.811 23.635H72.69300000000001V35.389H69.811z"
        />
        <path
          d="M46.215 23.649h9.42v2.527h-6.537v2.204h6.353v2.226h-6.353v2.319h6.537v2.464h-9.42v-11.74zM63.818 28.827h-3.589v-2.66h3.603c1.203 0 1.586.773 1.586 1.349 0 .787-.42 1.311-1.6 1.311zm2.491 1.215c1.574-.783 1.992-1.834 1.992-3.25 0-2.516-2.045-3.143-3.459-3.143h-7.496v11.74h2.883v-4.14h3.773c.996 0 1.391.994 1.416 1.99l.078 2.15h2.805l-.104-2.412c0-1.886-.525-2.777-1.888-2.935zM19.258 30.235l1.719-4.103 1.791 4.103h-3.51zm20.308-6.586l-3.537 7.875-3.525-7.875h-4.52v11.307l-5.031-11.307h-3.814l-5.137 11.74h3.041l1.125-2.621h5.754l1.141 2.621h5.802v-8.714l3.854 8.714h2.621l3.943-8.57v8.57h2.883v-11.74h-4.6zM84.732 30.241l1.717-4.104 1.809 4.104h-3.526zm17.706-6.598v8.129l-4.862-8.129h-4.26v11.026l-4.91-11.02h-3.814l-3.971 9.094s-1.678 0-1.848-.041c-.693-.143-1.779-.633-1.793-2.703v-.852c0-2.752 1.495-2.953 3.411-2.953h1.779v-2.551h-3.764c-1.31 0-4.162 1-4.258 5.823-.066 3.25 1.35 5.929 4.547 5.929h3.801l1.125-2.621h5.752l1.141 2.621h5.687v-8.597l5.188 8.597h3.931V23.643h-2.882z"
          fill="#0078a8"
        />
      </g>
    </svg>
  );
}

export default AmexComponent;

import React from "react";
import { isoToDate } from "helpers/index";

import { StyledLineItem, StyledQty } from "./cartStyles";

const CartItem = props => {
  const { item, siteContext } = props;

  return (
    <StyledLineItem>
      {/* <span>{item.id}</span> */}
      <div className="item-title">
        <span>{item.title}</span>
        <p>
          @ ${item.price} each x {item.qty}
        </p>
      </div>

      <div className="item-total">
        ${Number(item.price * item.qty).toFixed(2)}
      </div>
    </StyledLineItem>
  );
};

export default CartItem;

import React from "react";

function DiscoverSVG(props) {
  return (
    <svg
      viewBox="0 0 126 80"
      xmlns="https://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={1.41421}
      {...props}
    >
      <path
        d="M125.984 74.938a4.183 4.183 0 01-4.181 4.182H4.736a4.18 4.18 0 01-4.179-4.182V4.907A4.18 4.18 0 014.736.725h117.067a4.181 4.181 0 014.181 4.182v70.031z"
        fill="#f1f2f2"
        fillRule="nonzero"
      />
      <path
        d="M35.033 79.12h86.77a4.183 4.183 0 004.181-4.182V45.585c-8.328 5.279-39.726 23.48-90.951 33.535z"
        fill="#f58220"
        fillRule="nonzero"
      />
      <path
        d="M64.803 33.817c-3.457 0-6.26 2.699-6.26 6.031 0 3.543 2.68 6.19 6.26 6.19 3.486 0 6.24-2.682 6.24-6.121 0-3.416-2.736-6.1-6.24-6.1z"
        fill="url(#_Radial1)"
      />
      <path
        d="M31.943 42.852c-.724.653-1.654.934-3.136.934h-.616v-7.762h.616c1.482 0 2.377.264 3.136.949.791.705 1.262 1.795 1.262 2.92 0 1.129-.471 2.258-1.262 2.959zm-2.677-8.816h-3.364v11.738h3.348c1.775 0 3.061-.422 4.188-1.353a5.917 5.917 0 002.128-4.51c0-3.465-2.587-5.875-6.3-5.875z"
        fill="#001722"
        fillRule="nonzero"
      />
      <path
        fill="#001722"
        fillRule="nonzero"
        d="M36.623 34.036H38.908V45.774H36.623z"
      />
      <path
        d="M44.508 38.538c-1.375-.508-1.778-.844-1.778-1.477 0-.738.721-1.301 1.708-1.301.685 0 1.248.282 1.849.948l1.195-1.563a5.13 5.13 0 00-3.451-1.304c-2.074 0-3.66 1.443-3.66 3.361 0 1.621.738 2.447 2.887 3.221.898.316 1.355.527 1.584.669.457.301.687.721.687 1.213 0 .954-.756 1.655-1.779 1.655-1.092 0-1.971-.543-2.498-1.565l-1.477 1.428c1.053 1.547 2.321 2.234 4.065 2.234 2.375 0 4.047-1.586 4.047-3.855 0-1.865-.772-2.711-3.379-3.664zM48.607 39.911c0 3.453 2.711 6.127 6.198 6.127.986 0 1.83-.194 2.869-.686v-2.693c-.916.916-1.727 1.283-2.764 1.283-2.305 0-3.943-1.672-3.943-4.049 0-2.25 1.687-4.029 3.838-4.029 1.09 0 1.918.389 2.869 1.32v-2.693c-1.002-.51-1.832-.719-2.817-.719-3.468 0-6.25 2.729-6.25 6.139zM76.223 41.921l-3.131-7.885H70.59l4.984 12.039h1.231l5.07-12.039h-2.48l-3.172 7.885zM82.916 45.774h6.492v-1.988h-4.207v-3.17h4.047V38.63h-4.047v-2.606h4.207v-1.988h-6.492v11.738zM93.877 39.438h-.666v-3.556h.703c1.428 0 2.201.601 2.201 1.742 0 1.179-.773 1.814-2.238 1.814zm4.594-1.937c0-2.2-1.512-3.465-4.153-3.465H90.92v11.738h2.291v-4.717h.299l3.168 4.717h2.814l-3.697-4.945c1.726-.352 2.676-1.531 2.676-3.328z"
        fill="#001722"
        fillRule="nonzero"
      />
      <path
        d="M99.652 35.051h-.043v-.267h.045c.125 0 .186.043.186.131 0 .09-.063.136-.188.136zm.434-.14c0-.205-.139-.319-.391-.319h-.332v1.036h.246v-.403l.287.403h.309l-.344-.428a.283.283 0 00.225-.289z"
        fill="#231f20"
        fillRule="nonzero"
      />
      <path
        d="M99.744 35.848a.729.729 0 01-.723-.738c0-.414.319-.742.723-.742.391 0 .711.334.711.742 0 .406-.32.738-.711.738zm.002-1.644a.899.899 0 00-.902.904.9.9 0 00.902.902.903.903 0 000-1.806z"
        fill="#231f20"
        fillRule="nonzero"
      />
      <defs>
        <radialGradient
          id="_Radial1"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(64.793 39.927) scale(6.18057)"
        >
          <stop offset={0} stopColor="#ffeddf" />
          <stop offset={1} stopColor="#f4801f" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default DiscoverSVG;

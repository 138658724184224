import styled from "styled-components";

export const StyledLineItemsHolder = styled.div`
  padding: 14px;
  background: #efefef;
  border: 1px solid #777;
  border-radius: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0;
    }
  }

  div.cart-total {
    p {
      font-size: 1rem;
      font-weight: 600;
      margin: 0 0 2px;
      padding: 0;
      text-align: right;

      &.total {
        font-size: 1.25rem;
        font-weight: 800;
      }
    }
  }
`;

export const StyledCartHolder = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  div.cart-total {
    h6 {
      font-size: 1.2rem;
      margin: 0;
      padding: 0;
    }

    p {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
`;

export const StyledCartItem = styled.div`
  border: 1px solid #ccc;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
  padding: 1rem 0;

  div.main-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &:last-of-type {
    /* border-bottom-width: 1px; */
  }

  div.cart-item-details {
    max-width: 450px;

    p {
      font-size: 0.9rem;
      margin: 0 0 8px;

      &.cart-item-title {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.1;
      }
    }
  }

  div.cart-item-actions {
    padding: 1rem;
    background: rgba(200 200 200 / 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div.cart-item-costbig {
      font-size: 1.33rem;
      font-weight: bold;
    }
  }
`;

export const StyledNameEmailRow = styled.div`
  align-items: center;
  background: rgba(140, 140, 180, 0.1);
  border: 1px solid #ccc;
  border-width: 1px 1px 1px 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: space-between;
  margin: 0 0 13px;
  padding: 10px 10px 0;

  div.namebox,
  div.emailbox {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: calc(50% - 2rem);
    }
  }

  input {
    font-size: 13px;
  }

  label {
    margin-bottom: 0.2rem;
    font-weight: bold;
  }
`;

export const StyledLineItem = styled.div`
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 5px;
  padding: 0 0 5px;

  div.item-title {
    margin: 0 0 0.8rem;

    span {
      font-size: 13px;
      font-weight: 600;
    }

    p {
      font-size: 12px;
      margin: 0;
      padding: 0;
      line-height: 1;
    }
  }

  div.item-total {
    margin: 0;
    width: 100px;
    text-align: right;
  }
`;

export const StyledQty = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  span {
    display: inline-block;
    margin: 0 4px;
    padding: 3px 3px 3px 0;
    font-size: 14px;
    font-weight: 600;
  }

  input.item-count {
    padding: 5px 1px 5px 5px;
    font-weight: 600;
    border: 1px solid #ccc;
    color: rgb(120, 70, 70);
    width: 42px;
    line-height: 1;
  }

  button {
    padding: 0;
    margin: 0 4px;
    width: 24px;
  }
`;
